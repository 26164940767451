import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(156, 178, 211, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Header = styled(motion.div)`
  color: #ff6801;
  font-size: 2.4rem;
  font-weight: bold;
  position: absolute;
  top: 5rem;
  left: 130px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  .logo {
    display: inline-block;
    transform: translateY(7px);
  }
  .line {
    height: 28px;
    display: inline-block;
    margin: 0 2rem;
    width: 2px;
    background-color: #ff6801;
    transform: translateY(-10px);
  }
`

export const FormContainer = styled(motion.div)<{ hasError: boolean }>`
  width: 380px;
  padding: 3rem;
  border-radius: 6px;
  box-shadow: 2px 6px 6px 0 rgba(156, 178, 211, 0.14);
  background-color: #ffffff;
  position: relative;
  ${(p) =>
    p.hasError
      ? `
  input {
    color: #bc2600;
  }
`
      : null}
  h2 {
    color: #282e67;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 4rem;
  }
  .link {
    color: #ff6801;
  }
  h4 {
    color: #282e67;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 4rem;
  }
  .field {
    margin-bottom: 3rem;
  }

  .right-align {
    text-align: right;
  }

  .center-align {
    text-align: center;
  }

  .margin-bottom {
    margin-bottom: 5rem;
  }

  .forgot-btn {
    border: none;
    outline: none;
    cursor: pointer;
    color: #282e67;
    font-weight: 600;
    font-size: 1.4rem;
    text-decoration: underline;
  }

  .error {
    color: #bc2600;
    font-size: 1.4rem;
    font-weight: 500;
    font-style: italic;
    position: absolute;
    bottom: 170px;
    left: 35px;
  }

  .forget-header {
    display: flex;
    align-items: flex-start;
    button {
      display: inline-block;
      margin-right: 2rem;
      border: none;
      outline: none;
      cursor: pointer;
      transform: translateY(6px);
    }
  }

  .confirm-header {
    text-align: center;
  }
`
