import React from 'react'
import GlobalStyles from '../../elements/GlobalStyles'
import { Container, Header, FormContainer } from '../Login/style'
import Button from '../../components/Button'

import { motion } from 'framer-motion'
import Logo from '../Login/Logo'

export default function AppV2() {
  return (
    <>
      <GlobalStyles />
      <motion.div
        initial={{ opacity: 0 }}
        transition={{
          duration: 0.1,
        }}
        animate={{ opacity: 1 }}
      >
        <Container>
          <Header
            initial={{ x: -1000 }}
            animate={{ x: 0 }}
            transition={{ type: 'spring', duration: 0.4, delay: 0.3 }}
          >
            <span className="logo">
              <Logo />
            </span>{' '}
            <span className="line">&nbsp;</span>
            <span>publisher dashboard</span>
          </Header>
          <FormContainer
            hasError={false}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <h4 style={{ textAlign: 'center' }}>
              partner.heylink.com
              <br /> has been permanently moved to
              <br />
              <a href="https://publisher.heylink.com" className="link">
                publisher.heylink.com
              </a>
            </h4>
            <div className="right-align">
              <Button
                label="Login"
                disabled={false}
                leftPosition={38}
                handleClick={() => {
                  window.location.href = 'https://publisher.heylink.com'
                }}
              />
            </div>
          </FormContainer>
        </Container>
      </motion.div>
    </>
  )
}
