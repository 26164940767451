import styled from 'styled-components'
import { darken } from 'polished'
import { orange, inactive, white } from '../../elements'

export const StyledButton = styled.button`
  background: ${(p) => (p.disabled ? inactive : orange)};
  color: ${white};
  border-radius: 6px;
  padding: 12px 20px;
  border: 2px solid ${orange};
  cursor: pointer;
  transition: all 0.2s;
  font-weight: bold;
  position: relative;
  :hover {
    background: ${darken(0.02, orange)};
  }
  :disabled {
    background: #ebeff4;
    cursor: not-allowed;
    border: 2px solid #ebeff4;
  }
  .txt {
    visibility: hidden;
  }
  .loader {
    position: absolute;
    top: 25%;
    left: 45%;
    transform: translateX(-50%);
    border: 4px solid ${inactive};
    border-radius: 50%;
    border-top: 4px solid ${orange};
    width: 20px;
    height: 20px;
    -webkit-animation: loadingspinnerbtn 1s linear infinite; /* Safari */
    animation: loadingspinnerbtn 1s linear infinite;
  }
  outline: none;
  @-webkit-keyframes loadingspinnerbtn {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes loadingspinnerbtn {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const StyledAltButton = styled(StyledButton)`
  background: #f9fafb;
  color: #282e67;
  border: 2px solid #282e67;
  :hover {
    background: ${darken(0.02, '#f9fafb')};
  }
`
