import { createGlobalStyle } from 'styled-components'
import ProCyr from '../assets/fonts/HarmoniaSansProCyr-Bold.otf'
// import DMSansBold from '../assets/fonts/dmsans-bold-webfont.woff2'
// import DMSansRegular from '../assets/fonts/dmsans-regular-webfont.woff2'
// import DMSansMedium from '../assets/fonts/dmsans-medium-webfont.woff2'
/* @font-face {
        font-family: 'DMSans';
        src: local('DMSans'),
        url(${DMSansRegular}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'DMSans';
        src: local('DMSans'),
        url(${DMSansMedium}) format('woff2');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'DMSans';
        src: local('DMSans'),
        url(${DMSansBold}) format('woff2');
        font-weight: bold;
        font-style: normal;
    } */
const GlobalStyles = createGlobalStyle`
@font-face {
        font-family: 'ProCyr';
        src: local('ProCyr'),
        url(${ProCyr}) format('opentype'); 
        font-weight: bold;
        font-style: normal;
    }
   
  html{
    box-sizing: border-box;
    font-size: 62.5%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
  }
  body {
    padding: 0;
    margin: 0;
    /* font-family: 'Montserrat Alternates', sans-serif; */
    /* font-family: 'Raleway', sans-serif; */
    /* font-family: 'ProCyr', sans-serif; */
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'DM Sans', sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgba(156, 178, 211, 0.1); 
  }

  button {
    background-color: transparent;
  }
  main {
    position: relative;
    padding-left: 22rem;
    /* min-height: 100vh; */
    /* min-width: 100vw; */
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: 'ProCyr', sans-serif;
    font-weight: bold;
  }
`

export default GlobalStyles
