import React from 'react'
import ReactDOM from 'react-dom'
// import App from './App'
import AppV2 from './screens/AppV2'

ReactDOM.render(
  <React.StrictMode>
    <AppV2 />
  </React.StrictMode>,
  document.getElementById('root')
)
